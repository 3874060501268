.form {
  max-width: 320px;
  width: 100%;
}

.form label {
  display: block;
  font-size: 14px;
}

.form-error, .form small {
  color: red;
  font-size: 12px;
  margin-top: -12px;
  display: block;
}

.form input {
  background: #fff;
  border: none;
  display: block;
  line-height: 2;
  font-size: 16px;
  width: 100%;
  padding: 0 8px;
  margin-bottom: 16px;
  margin-top: 4px;
}

.form input.error {
  border: 1px solid red;
}

.form .btn {
  width: 100%;
}

