.gf-header {
  display: flex;
  justify-content: flex-end;
}

.gf-header a {
  color: rgb(30, 198, 156);
  font-size: 16px;
  font-weight: 600;
}

.gf-header a:hover {
  text-decoration: underline;
}

.gf-header a + a {
  margin: 0 14px;
}