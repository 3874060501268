:root {
  --fz: 16px;
  --theme--contrast-0: #121212;
  --theme-body-bg: #18061f;
  --theme-body-txt: #f3f3f3;
  --search-size-mx-h: 3rem;
  --zIndex-header: 20;
  --zIndex-component: 5;
  --brand-color_1: #00ff99;
  --brand-color_2: #00ccff;
  --brand-color_3: #9933ff;
  --brand-color_4: tomato;
  --brand-color_5: #fff35c;
  --brand-color_6: #8429de;
  --purple: #6f42c1;
  --bs-border-radius: .5rem;
  --bs-link-color: #333;
}
.custom-element {
  color: var(--purple);
}
.custom-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
}

.btn-bd-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet);
  --bs-btn-border-color: var(--bd-violet);
  --bs-btn-border-radius: .5rem;
  --bs-btn-hover-color: var(--bs-white);
  
}

html {
  box-sizing: border-box;
  font-size: 1rem;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fz);
  background-color: var(--theme-body-bg);
  color: var(--theme-body-txt);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: #09f;
  font-size: 32px;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


/* -------------------- rf-layout  - Component / header */
.o-header {
  background-color: inherit;
  position: sticky;
  top: 0;
  z-index: var(--zIndex-header);
  max-height: var(--search-size-mx-h);
}